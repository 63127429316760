
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { FormRulesMixin, VuetifyMixin } from '../../mixins';

import { OnboardingPictureUploadFormValue } from '../../../types/interfaces';

import ProfilePictureUpload from '../forms/ProfilePictureUpload.vue';

@Component({
	components: { ProfilePictureUpload },
})
export default class OnboardingUserInfo extends Mixins(FormRulesMixin, VuetifyMixin){
	@Prop({ default: () => ({
		valid: false,
		firstName: "",
		lastName: "",
	}) }) value: OnboardingPictureUploadFormValue;

	input(){
		this.$emit('input', this.value);
	}
	submit(){
		this.$emit('submit', this.value);
	}
}
