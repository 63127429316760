
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { FormRulesMixin, VuetifyMixin } from '../../mixins';

@Component({
	components: {  },
})
export default class OnboardingUserInfo extends Mixins(FormRulesMixin, VuetifyMixin){
	@Prop({ default: () => ({ autocompleteDiscoverable: true }) }) value: { autocompleteDiscoverable: boolean, valid: boolean  };

	input(){
		this.$emit('input', this.value);
	}
	submit(){
		this.$emit('submit', this.value);
	}
}
