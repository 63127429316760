
import { Component, Mixins } from 'vue-property-decorator';
import { AuthMixin, MultiStepFormMixin, VuetifyMixin } from '../../mixins';
import { OnboardingUserInfoFormValue } from '../../../types/interfaces';
import { userStore } from '../../store';

import Page from '../Page.vue';
import MultiStepForm from '../../components/forms/MultiStepForm.vue';
import OnboardingUserRole from '../../components/onboarding/OnboardingUserRole.vue';
import { RoleName } from '../../../types/enums';
import * as Routes from '../../../types/constants/web_client_user.routes';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import { AthleteProfileModel } from '@/models';

@Component({
	components: {
		Page,
		MultiStepForm,
		OnboardingUserRole,
	},
})
export default class RootOnboarding extends Mixins(AuthMixin, MultiStepFormMixin, VuetifyMixin) {
	loading: boolean = false;
	steps: number = 1;
	currentStep = 1;
	formValue: { step1: OnboardingUserInfoFormValue } = {
		step1: {
			valid: false,
			firstName: "",
			lastName: "",
			email: "",
			role: "",
		},
	};

	get Progress(): number{
		return 10;
	}

	get FormTitleText(): string{
		return "Which of the following best describes you?";
	}
	get FormSubTitleText(): string{
		return "In order to tailor the Best Athletes experience to your needs, start by letting us know who you are.";
	}

	get Loading(): boolean{
		return userStore.addAthleteProfileLoading;
	}

	nextStep(): void{
		let routeName: string;
		if(this.formValue.step1.role === RoleName.Athlete){
			routeName = Routes.AthleteProfileSetup;
		}else if(this.formValue.step1.role === RoleName.Parent){
			routeName = Routes.AthleteProfileSetup;
			this.formValue.step1.email = "";
		}else if(this.formValue.step1.role === RoleName.Coach){
			routeName = Routes.CoachProfileSetup;
		}

		/** Redirect user to existing profiles if they exist instead of onboarding again */
		if(this.formValue.step1.role === RoleName.Athlete && this.UserAthleteProfile !== null){
			userStore.useAthleteProfile({
				profileId: this.UserAthleteProfile.id,
			});
			this.$router.push('/');
		}else if(this.formValue.step1.role === RoleName.Coach && this.UserCoachProfile !== null){
			userStore.useCoachProfile({
				profileId: this.UserCoachProfile.id,
			});
			this.$router.push('/c');
		}else{
			const params: any = {
				name: routeName,
				// Props defined by MultiStepFormMixin
				params: {
					injectSteps: this.formValue,
					startingStep: 1,
				},
				query: this.$route.query,
			};
			this.$router.push(params);
		}
	}
	cancel(): void{
		this.$auth.logout();
	}

	async finish(): Promise<void>{
		console.log("this.formValue", this.formValue);
	}
	
	cancelSignup(): void{
		this.$auth.logout()
	}

	get UserAthleteProfile(): AthleteProfileModel | null{
		return userStore.OwnAthleteProfile;
	}
	get UserCoachProfile(): CoachProfileModel | null{
		return userStore.CoachProfile;
	}
}
