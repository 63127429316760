
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiChevronDown } from '@mdi/js';
import RoleCard from './RoleCard.vue';
import { FormRulesMixin, VuetifyMixin, AutofocusMixin, AvailableRoles } from '../../mixins';
import { RoleName } from '../../../types/enums';
import { RoleSelection } from '../../../types/interfaces';

@Component({
	components: { RoleCard },
})
export default class OnboardingUserRole extends Mixins(AutofocusMixin, FormRulesMixin, AvailableRoles, VuetifyMixin){
	mdiChevronDown = mdiChevronDown;

	@Prop({ type: Boolean, default: true }) submitOnClick: boolean;

	@Prop({ default: () => ([
		{
			role: RoleName.Athlete,
			img: 'athleteUnder18',
			description: `I am an <strong>Athlete</strong>`,
			options: {
				over18: true,
				over13: true,
			}
		},
		{
			role: RoleName.Coach,
			img: 'other',
			description: `I am a <strong>Coach</strong>`,
			options: {
				over18: true,
				over13: true,
			}
		},
		{
			role: RoleName.Parent,
			img: 'parent',
			description: `I am a <strong>Parent</strong> of an athlete`,
			options: {
				over18: true,
				over13: true,
			}
		},
	])}) roles: RoleSelection[];

	@Prop({ default: () => ({
		role: "",
		valid: false,
	}) }) value: {
		role: string,
		valid: boolean
	};

	updateRole(role: string): void{
		const value = {
			role: role,
			valid: true,
		};
		this.input(value);
		if(this.submitOnClick === true){
			this.submit(value);
		}
	}

	input(value: {role: string, valid: boolean}): void{
		this.$emit('input', value);
	}
	submit(value?:{ role: string, valid: boolean }): void{
		this.$emit('submit', value !== undefined ? value : this.value);
	}

}
