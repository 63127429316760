
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AutofocusMixin, SportsSelectorMixin, FormRulesMixin, VuetifyMixin } from '../../../mixins';

import { AthleteOnboardingFormValue } from '../../../../types/interfaces';

@Component
export default class OnboardingAthleteStats extends Mixins(AutofocusMixin, SportsSelectorMixin, FormRulesMixin, VuetifyMixin){

	@Prop({ default: () => ({valid: false}) }) value: AthleteOnboardingFormValue['step2'];
	@Prop({ type: Boolean, default: false }) isParent: boolean;

	weightUnits: string[] = ['KG', 'LBS'];
	heightUnits: string[] = ['CM', 'IN'];

	get BirthDateLabel():string {
		if(this.isParent){
			return "When is your child's birthday?";
		}
		return "Date of Birth";
	}
	get WeightLabel():string {
		if(this.isParent){
			return "Optional - How much does your child weigh?";
		}
		return "Optional - How much do you weigh?";
	}
	get HeightLabel():string {
		if(this.isParent){
			return "Optional - How tall is your child?";
		}
		return "Optional - How tall are you?";
	}
	get GenderLabel():string {
		if(this.isParent){
			return "You child is:";
		}
		return "Gender:";
	}

	input(): void{
		this.$emit('input', this.value);
	}
	submit(): void{
		this.$emit('submit', this.value);
	}
}
