
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CountriesSelectorMixin, FormRulesMixin, VuetifyMixin, AutofocusMixin } from '../../mixins';

import { OnboardingLocationFormValue } from '../../../types/interfaces';

@Component
export default class OnboardingLocation extends Mixins(AutofocusMixin, CountriesSelectorMixin, FormRulesMixin, VuetifyMixin){

	@Prop({ default: () => ({
		valid: false,
	}) }) value: OnboardingLocationFormValue;

	input(){
		this.$emit('input', this.value);
	}
	submit(){
		this.$emit('submit', this.value);
	}
}
