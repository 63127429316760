
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AutofocusMixin, SportsSelectorMixin, FormRulesMixin, VuetifyMixin } from '../../../mixins';

import { AthleteOnboardingFormValue } from '../../../../types/interfaces';

@Component
export default class OnboardingAthleteSportPosition extends Mixins(AutofocusMixin, SportsSelectorMixin, FormRulesMixin, VuetifyMixin){
	@Prop({ default: () => ({
		valid: false,
	}) }) value: AthleteOnboardingFormValue['step1'];

	@Prop({ type: Boolean, default: false }) isParent: boolean;

	get SportLabel(): string{
		if(this.isParent){
			return "What sport does your child play?";
		}
		return "What sport do you play?";
	}
	get PositionLabel(): string{
		if(this.isParent){
			return "What position does your child play?";
		}
		return "What position do you play?";
	}

	input(): void{
		this.$emit('input', this.value);
	}
	submit(): void{
		this.$emit('submit', this.value);
	}
}
