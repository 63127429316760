
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
import { RoleName } from '../../../types/enums';
// @ts-ignore
import parentRoleImg from '../../assets/img/coach.png';
// @ts-ignore
import athleteRoleImg from '../../assets/img/athlete2.png';
// @ts-ignore
import athleteUnder18RoleImg from '../../assets/img/athlete1.png';
// @ts-ignore
import otherRoleImg from '../../assets/img/other.png';

@Component
export default class RoleCard extends Mixins(VuetifyMixin){
	@Prop({ default: RoleName.Parent }) private role: RoleName;
	@Prop() private img: string;

	get RoleImg(): string{
		if(this.img === 'parent'){
			return parentRoleImg;
		}
		if(this.img === 'athlete'){
			return athleteRoleImg;
		}
		if(this.img === 'athleteUnder18'){
			return athleteUnder18RoleImg;
		}
		return otherRoleImg;
	}
}
