
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AutofocusMixin, SportsSelectorMixin, FormRulesMixin, VuetifyMixin, AuthMixin } from '../../../mixins';
import TOSCheckbox from '../../forms/TOSCheckbox.vue';

import { OnboardingCoachSportFormValue } from '../../../../types/interfaces';

@Component({
	components: { TOSCheckbox }
})
export default class OnboardingCoachSport extends Mixins(AutofocusMixin, AuthMixin, SportsSelectorMixin, FormRulesMixin, VuetifyMixin){

	@Prop({ default: () => ({
		valid: false,
		sport: "",
	}) }) value: OnboardingCoachSportFormValue;

	get TOSAccepted(): boolean{
		if(this.$auth.user.user_metadata === undefined) return false;
		if(this.$auth.user.user_metadata.tosAccepted) return true;
		return false;
	}

	input(){
		this.$emit('input', this.value);
	}
	submit(){
		this.$emit('submit', this.value);
	}
}
