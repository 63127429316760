
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AuthMixin, MultiStepFormMixin, VuetifyMixin, AppHostnameMixin } from '@/mixins';
import { AthleteOnboardingFormValue, OnboardingUserInfoFormValue } from '@/../types/interfaces';
import { userStore, navigationStore } from '@/store';

import Page from '@/views/Page.vue';
import MultiStepForm from '@/components/forms/MultiStepForm.vue';
import OnboardingClaimAthleteProfile from '@/components/onboarding/OnboardingClaimAthleteProfile.vue';
import OnboardingUserInfo from '@/components/onboarding/OnboardingUserInfo.vue';
import OnboardingAthleteSportPosition from '@/components/onboarding/athlete/OnboardingAthleteSportPosition.vue';
import OnboardingAthleteStats from '@/components/onboarding/athlete/OnboardingAthleteStats.vue';
import OnboardingAthleteLocation from '@/components/onboarding/athlete/OnboardingAthleteLocation.vue';
import OnboardingProfilePicture from '@/components/onboarding/OnboardingProfilePicture.vue';
import OnboardingDiscoverable from '@/components/onboarding/OnboardingDiscoverable.vue';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { RoleName, AthleteProfileRoleName, AthleteProfileRelationshipName, SportName } from '@/../types/enums';
import { AclPermission } from '@/../types/permissions';
import { setGAUserProperties } from '@/plugins/firebase';
import { PageState } from '@/models/PageState';
import { AthleteProfileStatus } from '@/../types/enums/athlete-profile-status.ts';

@Component({
	components: {
		Page,
		MultiStepForm,
		OnboardingClaimAthleteProfile,
		OnboardingUserInfo,
		OnboardingAthleteSportPosition,
		OnboardingAthleteStats,
		OnboardingAthleteLocation,
		OnboardingProfilePicture,
		OnboardingDiscoverable
	}
})
export default class AthleteOnboarding extends Mixins(AuthMixin, AppHostnameMixin, MultiStepFormMixin, VuetifyMixin) {
	@Prop({ default: false, type: Boolean }) disablePendingProfiles: boolean;

	loading: boolean = false;
	steps: number = 6;
	currentStep = 1;

	formValue: AthleteOnboardingFormValue<AthleteProfileModel> = this.getDefaultFormValue();

	getDefaultFormValue(): AthleteOnboardingFormValue<AthleteProfileModel>{
		// Don't overwrite step0 if it's been initialized
		let step0 = {
			valid: true,
			athlete: null,
			profiles: [],
		}
		// Don't overwrite step1 if it's been initialized
		let step1: OnboardingUserInfoFormValue = {
			valid: false,
			firstName: "",
			lastName: "",
			email: "",
			role: "",
			sport: SportName.Soccer,
		};
		if(this.formValue){
			step0 = this.formValue.step0;
			step1 = this.formValue.step1;
		}
		return {
			step0,
			step1,
			step2: {
				valid: false,
				birthDate: null,
				gender: null,
			},
			step3: {
				valid: false,
				country: "",
				city: "",
				fromOrganization: "",
				tosAgreed: false,
			},
			step4: {
				autocompleteDiscoverable: true,
				valid: true,
			},
			step5: {
				valid: true,
				pictureUrl: "",
			},
		};
	}

	get CurrentStepKey(): string {
		return `step${this.currentStep-1}`;
	}

	get PendingAthleteProfiles(): AthleteProfileModel[]{
		if(this.disablePendingProfiles === true) return [];
		if(this.PendingProfilesState.IsError) return [];
		return userStore.pendingAthleteProfiles
	}

	get PendingProfilesState(): PageState{
		return userStore.pendingAthleteProfilesState
	}

	/**
	 * Returns true when profiles failed to load since this happens if you visit this page without a verified email address.
	 * In that case we just pretend you have 0 pending profiles.  If your email is unverified, you will be presented the verify screen at the end of the onboarding process in this case.
	 */
	get PendingProfilesReady(): boolean{
		return this.PendingProfilesState.IsReady || this.PendingProfilesState.IsError;
	}

	get CurrentStepIsPendingProfiles(): boolean{
		return this.currentStep === 1;
	}

	get IsParent(): boolean{
		return this.formValue.step1.role === RoleName.Parent;
	}

	get FormTitleText(): string{
		switch (this.currentStep) {
		case 1: return "Let's get your profile setup.";
		case 2: return "Great! Let's get some basic info.";
		case 3: return "Awesome! Getting closer!";
		case 4: return "Nice, you're almost done.";
		case 5: return "Time to shine!"
		case 6: return "Last step and you're all set!";
		case 7: return "Ok, your registration is complete!";
		default:
			return "Nice, you're almost done.";
		}
	}
	get FormSubTitleText(): string{
		switch (this.currentStep) {
		case 1:
			if(this.PendingProfilesState.IsLoading){
				return "Please wait while we setup your account...";
			}
			return "Select your profile below";
		case 5: return "Would you like your recruiting profile to be searchable by coaches?"
		case 6: return "You can provide an optional profile photo.";
		default:
			if(this.formValue.step0.athlete === null){
				return "Please answer the questions below.";
			}else{
				// Pre-created profile was selected
				return "Please verify the information we have is correct.";
			}
		}
	}

	state: PageState = new PageState("Initial");
	get Loading(): boolean{
		return this.state.IsLoading;
	}

	async created (): Promise<void> {
		if(this.disablePendingProfiles === false){
			await userStore.loadPendingAthleteProfiles();
		}
		if(this.formValue.step1.role === RoleName.Athlete){
			// Name may or may not be present depending on signup method
			if(this.$auth.auth0User.given_name) this.formValue.step1.firstName = this.$auth.auth0User.given_name;
			if(this.$auth.auth0User.family_name) this.formValue.step1.lastName = this.$auth.auth0User.family_name;
		}
		// Email should always be available
		if(this.$auth.auth0User.email) this.formValue.step1.email = this.$auth.auth0User.email;
		await this.$auth.refreshToken(true);
		this.state = new PageState("Ready");
	}

	claimAthleteProfileNext(): void{
		if(this.formValue.step0.athlete !== null){
			this.athleteToFormValue(this.formValue.step0.athlete);
		}else{
			this.formValue = this.getDefaultFormValue();
		}
		this.next();
	}

	athleteToFormValue(athlete: AthleteProfileModel): void{
		this.formValue.step1.firstName = athlete.firstName;
		this.formValue.step1.lastName = athlete.lastName;
		this.formValue.step1.email = athlete.email;
		this.formValue.step2.birthDate = athlete.birthDate;
		this.formValue.step2.gender = athlete.gender;
		this.formValue.step3.country = athlete.country;
		this.formValue.step3.city = athlete.city;
		this.formValue.step3.fromOrganization = athlete.fromOrganization;
		this.formValue.step3.tosAgreed = athlete.tosAgreed;
		this.formValue.step4.autocompleteDiscoverable= athlete.autocompleteDiscoverable;
		this.formValue.step5.pictureUrl = athlete.pictureUrl;
	}

	getAthleteFromFormValue(): AthleteProfileModel{
		let athlete: AthleteProfileModel;
		if(this.formValue.step0.athlete !== null){
			athlete = this.formValue.step0.athlete;
			athlete.profileStatus = AthleteProfileStatus.PlaceholderClaimed;
		}else{
			athlete = new AthleteProfileModel();
		}
		athlete.firstName = this.formValue.step1.firstName;
		athlete.lastName = this.formValue.step1.lastName;
		athlete.email = this.formValue.step1.email;
		athlete.sports = [{
			id: this.formValue.step1.sport,
			primaryPosition: null,
			positions: []
		}];
		athlete.birthDate = this.formValue.step2.birthDate;
		athlete.gender = this.formValue.step2.gender;
		athlete.country = this.formValue.step3.country;
		athlete.city = this.formValue.step3.city;
		athlete.fromOrganization = this.formValue.step3.fromOrganization;
		athlete.tosAgreed = this.formValue.step3.tosAgreed;
		athlete.autocompleteDiscoverable = this.formValue.step4.autocompleteDiscoverable;
		athlete.pictureUrl = this.formValue.step5.pictureUrl;
		athlete.recruitingProfileComplete = true;
		return athlete;
	}

	async athleteOnboardingFinish(): Promise<void>{
		this.state = new PageState("Loading");
		try{
			await this.finish();
		}catch(e){
			this.state = PageState.getPageState(e);
		}finally{
			this.state = new PageState("Ready");
		}
	}
	async finish(): Promise<void>{
		const athlete = this.getAthleteFromFormValue();
		const role = this.formValue.step1.role;

		let toProfile: AthleteProfileModel;
		if(role === RoleName.Parent){
			athlete.setUser(this.$auth.user.id, {
				userId: this.$auth.user.id,
				role: {
					name: AthleteProfileRoleName.Owner,
					permissions: [AclPermission.Owner],
				},
				relationship: {
					name: AthleteProfileRelationshipName.Parent,
				},
			});
			const child = await userStore.addChildAthleteProfile({ athlete });
			toProfile = child;
			setGAUserProperties({
				is_parent: true,
			});
		}else{
			athlete.setUser(this.$auth.user.id, {
				userId: this.$auth.user.id,
				role: {
					name: AthleteProfileRoleName.Owner,
					permissions: [AclPermission.Owner],
				},
				relationship: {
					name: AthleteProfileRelationshipName.Athlete,
				},
			});
			const newAthlete = await userStore.addAthleteProfile({ athlete: athlete });
			toProfile = newAthlete;
			setGAUserProperties({
				is_athlete: true,
			});
		}
		await userStore.acceptTermsOfService(this.formValue.step3.tosAgreed);
		setGAUserProperties({
			onboarding_from_organization: this.formValue.step3.fromOrganization,
		});
		await userStore.useAthleteProfile({
			profileId: toProfile.id,
		});
		await userStore.refreshToken(true);
		if(navigationStore.HasNavigationQueued){
			return navigationStore.popNavigation();
		}
		this.$router.push('/');
	}

	cancelSignup(): void {
		this.$auth.logout()
	}

	async athleteOnboardingNext(validate?: () => Promise<boolean>): Promise<void>{
		this.next(validate);
	}
	athleteOnboardingPrevious(): void{
		if(this.currentStep === 2 && this.formValue.step0.profiles.length === 0){
			// Don't step back to the profile setup step if there are no profiles
			window.history.back();
			return;
		}
		this.previous();
	}
}
